import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Legend,
  Tooltip,
} from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Legend, Tooltip);

function BarChart({ paymentData }) {
  if (!paymentData || !paymentData.labels || !paymentData.totalAmount) {
    console.error('Error: paymentData is undefined or missing totalAmount.');
    return null;
  }

  const totalAmount = paymentData.totalAmount;
  const labels = paymentData.labels.map((date) => new Date(date).toLocaleDateString());

  // Convert amounts into percentages
  const amountsInNaira = paymentData.data; // Keep original amount
  const amountsInPercentage = paymentData.data.map(amount => (amount / totalAmount) * 100);

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Monthly Payment Performance (%)',
        data: amountsInPercentage, // Use percentage for the chart
        backgroundColor: '#669906',
        borderColor: 'aqua',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const index = context.dataIndex;
            const rawAmount = amountsInNaira[index]; // Get actual Naira amount
            return `Total Paid: ₦${rawAmount.toLocaleString()}`;
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        beginAtZero: true,
        max: 100, 
        title: {
          display: true,
          text: 'Percentage (%)',
        },
      },
    },
  };

  return (
    <div>
      <div style={{ height: '200px' }}>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
}

export default BarChart;