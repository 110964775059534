import React, { useState, useEffect } from 'react';
import Compo from '../Others/Compo';

import { Link } from 'react-router-dom';
import Navigation from '../Others/Navigation';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { BASE_URL } from '../../utils/globals';

function PaidHouses() {
    let estate_id = localStorage.getItem('estate_id');


    const [payments, setPayments] = useState([]);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [payment_date, setPaymentDate] = useState('');
    const [maintenance_id, setServiceType] = useState('');
    const [period_from, setPeriodFrom] = useState('');
    const [to, setTo] = useState('');
    const [due_date, setDueDate] = useState('');
    const [edit, setEdit] = useState(false);
    const [searchBox, setSearchBox] = useState('');
    const [viewedPayment, setViewedPayment] = useState([]);
    const [refresh, setRefresh] = useState('');
    const [selectedPaymentIndex, setSelectedPaymentIndex] = useState(null);
    const [maintenanceData, setMaintenanceData] = useState([]);

    let is__isAdmin = localStorage.getItem('role');
    let is_logged_in = localStorage.getItem('user_id');

    let isAdmin = is__isAdmin === 'ADMIN' ? true : false;
    let isLoggedIn = is_logged_in ? true : false;



    const fetchPayments = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/payments/paid-houses/estate/${estate_id}`);
            console.log(response.data);

            if (Array.isArray(response.data)) {
                setPayments(response.data);
            } else {
                console.error('Invalid data format received for payments');
            }
        } catch (error) {
            console.error('Error fetching paid houses:', error);
        }
    };

    useEffect(() => {
        fetchPayments();
    }, []);





    const fetchUserPayments = async (searchCriteria) => {
      try {
        const response = await axios.get(
          `${BASE_URL}/payment/estate/${estate_id}?search=${searchCriteria}`
        );

        console.log(response.data);

        if (Array.isArray(response.data)) {
          setPayments(response.data);
        } else {
          console.error('Invalid data format received for payments');
        }
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      fetchUserPayments();
    }, []);

    const handleSearch = () => {
      // alert(searchBox);
      fetchUserPayments(searchBox);
    };



    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString();
    };

    // const handleKeyPress = (e) => {
    //   if (e.key === 'Enter') {
    //     e.preventDefault();
    //   }
    // };

    // const handleDatechange = (event) => {
    //   setDueDate(event.target.value);
    // };

    const handleSearchBoxChange = (event) => {
        setSearchBox(event.target.value);
    };









    return (
        <div>
            {isLoggedIn && isAdmin ? (
                <div className='add-payments d-flex'>
                    <div className='users'>
                        <Compo />
                    </div>

                    <div className='users_'>
                        <Navigation />
                        <div
                            className='add_pay mt-4'
                            style={{ width: '97%', margin: 'auto' }}
                        >
                            <h5>ALL PAID HOUSES</h5>
                            <br />
                            <div className='resident-txt-field'>
                                {/*  */}
                            </div>
                            <div className='all-residents_'>
                                <div className='all_residents-inputfield'>
                                    <div className='input-field'>
                                        <input
                                            type='text'
                                            placeholder='Search Keyword...'
                                            value={searchBox}
                                            onChange={handleSearchBoxChange}
                                        />
                                    </div>

                                    <div className='filter_btn'>
                                        <button
                                            className='all__residents-btn'
                                            onClick={handleSearch}
                                        >
                                            FILTER LIST
                                        </button>
                                    </div>

                                </div>


                                <br />
                                <br />

                                <div className='table__records'>
                                    <table className='table table-bordered table-hover'>
                                        <thead>
                                            <tr>
                                                <th scope='col'>S/NO</th>
                                                <th scope='col'>HOUSE</th>
                                                <th scope='col'>SERVICE TYPE</th>
                                                <th scope='col'>AMOUNT</th>
                                                <th scope='col'>PAYMENT DATE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(payments) && payments.length > 0 ? (
                                                payments.map((item, index) => (
                                                    <tr key={item._id || index}>
                                                        <td>{index + 1}</td>
                                                        <td>{`${item.house_id?.house_number} ${item.house_id?.house_address} ${item.house_id?.house_type}`}</td>

                                                        <td>{item.maintenance_id?.services}</td>
                                                        <td>{item.amount}</td>
                                                        <td>{formatDate(item.payment_date)}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="5" className="text-center">No paid houses found</td>
                                                </tr>
                                            )}
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>You do not have the permission to view this page</>
            )}
        </div>
    );
}

export default PaidHouses;