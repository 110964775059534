import React, { useState, useEffect } from 'react';
import Compo from '../Others/Compo';
import { HiOutlineSearch } from 'react-icons/hi';
import { IoMdArrowDropdown } from 'react-icons/io';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
// import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import Navigation from '../Others/Navigation';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import SingleResidentProfile from './SingleResidentProfile';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { BASE_URL } from '../../utils/globals';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer, toast } from 'react-toastify';

function AllResidents() {
  let estate_id = localStorage.getItem('estate_id');
  const [edit, setEdit] = useState(false);
  const [users, setUsers] = useState([]);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [occupation, setOccupation] = useState('');
  const [title, setTitle] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [serviceType, setServiceType] = useState(null);
  const [maintenance_id, setMaintenance] = useState('');
  const [house_id, setHouse] = useState('');
  const [to, setTo] = useState(null);
  const [period_from, setPeriodFrom] = useState(null);
  const [amount, setAmount] = useState('');
  const [payment_date, setPaymentDate] = useState(null);
  const [due_date, setDueDate] = useState(null);
  const [id, setId] = useState('');
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [identificationCode, setIdentificationCode] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [periodFromDate, setPeriodFromDate] = useState(null);
  const [paymentDate, setPaymentDatee] = useState(null);
  const [dueDate, setDueDatee] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedUserPayments, setSelectedUserPayments] = useState([]);
  const [searchBox, setSearchBox] = useState('');
  const [selectedUserForPayment, setSelectedUserForPayment] = useState(null);
  const [paymentFirstName, setPaymentFirstName] = useState('');
  const [paymentLastName, setPaymentLastName] = useState('');
  const [paymentEmail, setPaymentEmail] = useState('');
  const [paymentAddress, setPaymentAddress] = useState('');
  const [paymentPhone, setPaymentPhone] = useState('');
  const [residence_id, setResidenceId] = useState('')
  const [status_id, setStatusId] = useState('')
  const [ownership_id, setOwnershipId] = useState('')
  const [residenceCategory, setResidenceCategory] = useState("");
  const [residences, setResidences] = useState([]);
  const [home, setHome] = useState([]);
  const [houses, setHouses] = useState([]);


  const [avatar, setAvatar] = useState(null);

  const [selectedDate, setSelectedDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);


  let is__isAdmin = localStorage.getItem('role');
  let is_logged_in = localStorage.getItem('user_id');

  let isAdmin = is__isAdmin === 'ADMIN' ? true : false;
  let isLoggedIn = is_logged_in ? true : false;



  const handlePayment = () => {
    console.log('Firstname:', firstname);
    console.log('Lastname:', lastname);
    const Residencepayments = {
      firstname: paymentFirstName,
      lastname: paymentLastName,
      address,
      email,
      phone,
      amount,
      payment_date: paymentDate,
      maintenance_id,
      house_id,
      period_from: periodFromDate,
      to: toDate,
      due_date: dueDate,
      estate_id,
    };
    console.log(Residencepayments);

    const paymentEndpoint = `${BASE_URL}/payments`;

    axios
      .post(paymentEndpoint, Residencepayments)
      .then((response) => {
        const newPayment = response.data;

        setDueDate('');
        setServiceType('');
        setPaymentDate('');
        setPeriodFrom('');
        setTo('');
        setAmount('');
        setSelectedDate(null);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    axios.get(`${BASE_URL}/dues/estate/${estate_id}`).then((response) => {
      setServiceType(response.data);

      // console.log(response);
    });
  }, []);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/houses/estate/${estate_id}?limit=9999`)
      .then((response) => {
        const houseDocs = response.data.docs;
        setHouses(houseDocs);
      });
  }, [estate_id]);

  useEffect(() => {
    console.log("Fetching data for page:", currentPage);
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/users/estate/${estate_id}?page=${currentPage}&limit=100`
        );
        console.log("Response Data:", response.data);

        const { users, totalPages } = response.data;
        setUsers(users);
        setTotalPages(totalPages);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchData();
  }, [currentPage, estate_id]); 




  const nextPage = () => {
    if (currentPage < totalPages) {
      console.log("Before updating:", currentPage);
      setCurrentPage(prev => {
        console.log("After updating:", prev + 1); 
        return prev + 1;
      });
    }
  };



  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };



  const fetchUserData = async (searchCriteria) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/users/estate/${estate_id}?search=${searchCriteria}&page=1`
      );
      console.log('Fetched users:', response.data.users);
      setUsers(response.data.users);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };


  useEffect(() => {
    if (estate_id) fetchUserData('');
  }, [estate_id]);


  const handleSearch = () => {
    fetchUserData(searchBox);
    console.log('Users after search:', users);
  };

  const handleSearchBoxChange = (event) => {
    setSearchBox(event.target.value);
  };

  const handleFirstnameChange = (event) => {
    setFirstname(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleLastnameChange = (event) => {
    setLastname(event.target.value);
  };
  const handleOccupationChange = (event) => {
    setOccupation(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleServiceChange = (event) => {
    setMaintenance(event.target.value);
  };

  const handleHouseChange = (event) => {
    setHouse(event.target.value);
  };
  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };
  const handleResidenceChange = (event) => {
    const selectedId = event.target.value;
    const selectedResidence = residences.find(res => res._id === selectedId);

    setResidenceId(selectedResidence?._id || "");
  };

  const handleHomeChange = (event) => {
    const selectedId = event.target.value;
    const selectedHome = home.find((res) => res._id === selectedId);

    setStatusId(selectedHome?._id || "");
  };

  const handleHousesChange = (event) => {
    const selectedId = event.target.value;
    const selectedHouses = houses.find((res) => res._id === selectedId);

    setHouse(selectedHouses?._id || "");
  };



  const handleEdit = (user) => {
    setEdit(true);
    setSelectedUser(user);
    setFirstname(user.firstname);
    setLastname(user.lastname);
    setAddress(user.address);
    setPhone(user.phone);
    setEmail(user.email);
    setTitle(user.title);
    setOccupation(user.occupation);
    setResidenceId(user.residence_id?._id || "");
    setResidenceCategory(user.residence_id?.category || "");
    setStatusId(user.status_id?._id || "");
    setHouse(user.house_id?._id || "");
    setOwnershipId(user.ownership_id?._id || "");
    setAvatar(null);
  };

  useEffect(() => {
    const fetchResidences = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/residence/estate/${estate_id}`);
        setResidences(response.data);
      } catch (error) {
        console.error("Error fetching residences:", error);
      }
    };

    fetchResidences();
  }, []);

  useEffect(() => {
    const fetchHome = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/status/estate/${estate_id}`);
        setHome(response.data);
      } catch (error) {
        console.error("Error fetching residences:", error);
      }
    };

    fetchHome();
  }, []);



  const handleUpdateImage = async (user_id) => {
    try {
      const formData = new FormData();
      formData.append('avatar', avatar);

      const response = await axios.put(
        `${BASE_URL}/updateavatar/${user_id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response.data;

    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();

    const updatedUser = {
      firstname,
      lastname,
      address,
      phone,
      email,
      title,
      occupation,
      residence_id: residence_id?._id || residence_id || "",
      status_id,
      ownership_id,
      house_id,
    };

    console.log("Updating user with data:", JSON.stringify(updatedUser, null, 2));

    const adminsId = localStorage.getItem("user_id");
    const userId = selectedUser._id;

    try {
      const response = await axios.put(
        `${BASE_URL}/users/${adminsId}/${userId}`,
        updatedUser
      );

      console.log("Server Response:", response.data);


      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user._id === userId ? { ...user, ...updatedUser } : user
        )
      );

      setEdit(false);
      setSelectedUser(null);
      setFirstname("");
      setLastname("");
      setAddress("");
      setPhone("");
      setEmail("");
      setTitle("");
      setOccupation("");
      setResidenceId("");
      setHouse("");
      setOwnershipId("");
      setStatusId("");
      setAvatar(null);

      handleUpdateImage(userId).catch((err) =>
        console.error("Image update failed:", err)
      );

      toast.success("User updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };



  const handleDelete = async (usersId) => {
    try {
      await axios.delete(`${BASE_URL}/users/${usersId}`);
      setUsers(users.filter((item) => item._id !== usersId));
      // console.log(response.data.message);
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };


  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setAvatar(file);
  };

  const handleViewProfile = (user_id) => {
    const user = users.find((item) => item._id === user_id);

    setSelectedUserPayments(user?.payments || []);

    setPaymentFirstName(user?.firstname || '');
    setPaymentLastName(user?.lastname || '');
    setPaymentEmail(user?.email || '');
    setPaymentAddress(user?.address || '');
    setPaymentPhone(user?.phone || '');
    setHouse(user?.house_id || '');

    setSelectedUserForPayment(user);
    setShowPaymentModal(true);
  };

  return (
    <div>
      <ToastContainer />
      {isLoggedIn && isAdmin ? (
        <div className='create_residents d-flex'>
          <div className='users'>
            <Compo />
          </div>
          <div className='users_'>
            <Navigation />
            <br />
            <div
              className='residents--form'
              style={{ width: '97%', margin: 'auto' }}
            >
              <h5>VIEW RESIDENTS</h5>
              <br />
              <div className='resident-txt-field'>
                <div className='txt--inside d-flex justify-content-between'>
                  <div className='cisearch-icon d-flex gap-1'>
                    <div className='search--icon p-1'>
                      <HiOutlineSearch />
                    </div>
                    <h4>Filter Residents List</h4>
                  </div>
                  <div class='btn-group'>
                    <button
                      class='dropdown-btn btn-sm'
                      type='button'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      <IoMdArrowDropdown />
                    </button>
                  </div>
                </div>
              </div>
              <div className='all-residents_'>
                <div className='all_residents-inputfield'>
                  <div className='input-field'>
                    <input
                      type='text'
                      placeholder='Search keyword...'
                      value={searchBox}
                      onChange={handleSearchBoxChange}
                    />
                  </div>

                  <div className='filter_btn'>
                    <button
                      className='all__residents-btn'
                      onClick={handleSearch}
                    >
                      FILTER LIST
                    </button>
                  </div>
                </div>
                <div className='all_residents--inputfield'>
                  <div className='resident-media'>
                    <div className='resident-media-input'>
                      <input
                        type='text'
                        placeholder='search keyword...'
                        value={searchBox}
                        onChange={handleSearchBoxChange}
                      />
                    </div>
                    <br />
                    <div>
                      <button
                        className='resident-media-btn'
                        onClick={handleSearch}
                      >
                        Filter List
                      </button>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div
                  className='edit_section'

                >
                  {edit && (
                    <Form className='set_edit' onSubmit={handleUpdate}>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          type='text'
                          name='title'
                          value={title}
                          placeholder='Title'
                          onChange={handleTitleChange}
                        />
                      </Form.Group>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>First_Name</Form.Label>
                        <Form.Control
                          type='text'
                          name='firstname'
                          value={firstname}
                          placeholder='first_name'
                          onChange={handleFirstnameChange}
                        />
                      </Form.Group>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Last_Name</Form.Label>
                        <Form.Control
                          type='text'
                          name='lastName'
                          value={lastname}
                          placeholder='last_name'
                          onChange={handleLastnameChange}
                        />
                      </Form.Group>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type='text'
                          name='email'
                          value={email}
                          placeholder='email'
                          onChange={handleEmailChange}
                        />
                      </Form.Group>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Contact</Form.Label>
                        <Form.Control
                          type='phone'
                          name='phone'
                          value={phone}
                          placeholder='phone_No'
                          onChange={handlePhoneChange}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formResidence">
                        <Form.Label>Residence Status</Form.Label>
                        <Form.Select name="residence_id" value={residence_id || ""} onChange={handleResidenceChange}>
                          <option value="">Select Residence</option>
                          {residences.map((res) => (
                            <option key={res._id} value={res._id}>
                              {res.category}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formResidence">
                        <Form.Label>Home Status</Form.Label>
                        <Form.Select name="status_id" value={status_id || ""} onChange={handleHomeChange}>
                          <option value="">Select Home</option>
                          {home.map((res) => (
                            <option key={res._id} value={res._id}>
                              {res.resident}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formResidence">
                        <Form.Label>House Number</Form.Label>
                        <Form.Select name="house_id" value={house_id || ""} onChange={handleHousesChange}>
                          <option value="">House No.</option>
                          {houses.map((res) => (
                            <option key={res._id} value={res._id}>
                              {`${res.house_number}${res.house_type}${res.house_address}`}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Occupation</Form.Label>
                        <Form.Control
                          type='text'
                          name='occupation'
                          value={occupation}
                          placeholder='Occupation'
                          onChange={handleOccupationChange}
                        />
                      </Form.Group>
                      <Form.Group controlId='profileImage'>
                        <Form.Label>Profile Image</Form.Label>
                        <Form.Control
                          type='file'
                          accept='image/*'
                          onChange={handleImageChange}
                        />
                      </Form.Group>
                      <br />
                      <Button variant='primary' type='submit'>
                        update
                      </Button>
                    </Form>
                  )}
                </div>
                <br />
                <div className='table__records'>
                  <table className='table table-bordered table-hover'>
                    <thead>
                      <tr>
                        <th scope='col'>ID</th>
                        <th scope='col'>TITLE</th>
                        <th scope='col'>FIRST_NAME</th>
                        <th scope='col'>LAST_NAME</th>
                        <th scope='col'>RESIDENT STATUS</th>
                        <th scope='col'>HOME STATUS</th>
                        <th scope='col'>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users?.length > 0 ? (
                        users.map((item) => (
                          <tr key={item._id}>
                            <td>{item.identificationCode}</td>
                            <td>{item.title}</td>
                            <td>{item.firstname}</td>
                            <td>{item.lastname}</td>
                            <td>{item.residence_id ? item.residence_id.category : '-'}</td>
                            <td>{item.status_id ? item.status_id.resident : '-'}</td>

                            <td>
                              <div className="btn-group" style={{ marginLeft: '30px' }}>
                                <button
                                  className="dropdown-btn btn-sm"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <FontAwesomeIcon icon={faEllipsisVertical} style={{ width: '100%' }} />
                                </button>
                                <ul className="dropdown-menu">
                                  <div className="drop__down" style={{ width: '90%', margin: 'auto' }}>
                                    <div className="dropdown__details d-flex justify-content-between">
                                      <div>
                                        <FontAwesomeIcon icon={faExpand} />
                                      </div>
                                      <td>
                                        <Link
                                          className="li_nk"
                                          style={{
                                            textDecoration: 'none',
                                            fontWeight: '700',
                                            color: '#0099cc',
                                          }}
                                          to={`/residence/${item._id}/${item.identificationCode}`}
                                          onClick={() => handleViewProfile(item._id)}
                                        >
                                          VIEW
                                        </Link>
                                      </td>
                                    </div>
                                    <br />
                                    <div className="dropdown___details d-flex justify-content-between">
                                      <div>
                                        <FontAwesomeIcon icon={faPenToSquare} />
                                      </div>
                                      <button
                                        className="dropdown___button_"
                                        style={{
                                          border: 'none',
                                          background: 'transparent',
                                          fontWeight: '700',
                                          color: '#0099cc',
                                        }}
                                        onClick={() => handleEdit(item)}
                                      >
                                        EDIT
                                      </button>
                                    </div>
                                    <br />
                                    <div className="dropdown___details__ d-flex justify-content-between">
                                      <div>
                                        <FontAwesomeIcon icon={faTrash} />
                                      </div>
                                      <button
                                        onClick={() => handleDelete(item._id)} // Ensure correct `item._id` usage
                                        className="dropdown___button_"
                                        style={{
                                          border: 'none',
                                          background: 'transparent',
                                          fontWeight: '700',
                                          color: '#0099cc',
                                        }}
                                      >
                                        DELETE
                                      </button>
                                    </div>
                                  </div>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7" style={{ textAlign: 'center', padding: '10px' }}>
                            Loading...
                          </td>
                        </tr>
                      )}
                    </tbody>

                  </table>
                </div>

                <div className='pagination'>
                  <button
                    className='pagination-btn'
                    onClick={prevPage}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    className='pagination-btn'
                    onClick={nextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>
              {selectedUserId && (
                <SingleResidentProfile
                  identificationCode={identificationCode}
                  usersId={selectedUserId}
                />
              )}

              <Modal
                show={showPaymentModal}
                onHide={() => {
                  setShowPaymentModal(false);
                  setFirstname('');
                  setLastname('');
                  setAddress('');
                  setEmail('');
                  setPhone('');
                  setHouse('');
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Payment Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <div className='modal_form'>
                      <Form.Group controlId='firstname'>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type='text'
                          name='firstname'
                          value={paymentFirstName}
                          readOnly
                        />
                      </Form.Group>

                      <Form.Group controlId='lastname'>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type='lastname'
                          name='lastname'
                          value={paymentLastName}
                          readOnly
                        />
                      </Form.Group>

                      <Form.Group controlId='email'>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type='email'
                          name='email'
                          value={paymentEmail}
                          readOnly
                        />
                      </Form.Group>
                      <Form.Group controlId='phone'>
                        <Form.Label>Contact</Form.Label>
                        <Form.Control
                          type='phone'
                          name='phone'
                          value={paymentPhone}
                          readOnly
                        />
                      </Form.Group>

                      <Form.Group controlId='amount'>
                        <Form.Label>House</Form.Label>
                        <Form.Control
                          type='house_id'
                          name='house_id'
                          value={`${house_id.house_number} ${house_id.house_type} ${house_id.house_address}`}
                          onChange={handleHouseChange}
                          readOnly
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group controlId='amount'>
                        <Form.Label>Amount</Form.Label>
                        <Form.Control
                          type='amount'
                          name='amount'
                          value={amount}
                          onChange={handleAmountChange}
                          autoComplete='off'
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group controlId='service_type'>
                        <Form.Label>Service_Type</Form.Label>
                        <Form.Control
                          as='select'
                          name='maintenance_id'
                          value={maintenance_id}
                          onChange={handleServiceChange}
                        >
                          <option value=''>Select service Type</option>
                          {serviceType &&
                            serviceType.map((e) => (
                              <option key={e._id} value={e._id}>
                                {e.services}
                              </option>
                            ))}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group controlId='payment_date'>
                        <Form.Label>Payment_Date</Form.Label>
                        <DatePicker
                          type='payment_date'
                          name='payment_date'
                          selected={paymentDate}
                          onChange={(date) => setPaymentDatee(date)}
                          autoComplete='off'
                        />
                      </Form.Group>
                      <Form.Group controlId='period_from'>
                        <Form.Label>Period_From</Form.Label>
                        <DatePicker
                          type='period_from'
                          name='period_from'
                          selected={periodFromDate}
                          onChange={(date) => setPeriodFromDate(date)}
                          dateFormat='MM/dd/yyyy'
                          autoComplete='off'
                        />
                      </Form.Group>
                      <Form.Group controlId='to'>
                        <Form.Label>To_Date</Form.Label>
                        <DatePicker
                          type='to'
                          name='to'
                          selected={toDate}
                          onChange={(date) => setToDate(date)}
                          autoComplete='off'
                        />
                      </Form.Group>

                      <Form.Group controlId='due_date'>
                        <Form.Label>Due_Date</Form.Label>
                        <DatePicker
                          type='due_date'
                          name='due_date'
                          selected={dueDate}
                          onChange={(date) => setDueDatee(date)}
                          autoComplete='off'
                        />
                      </Form.Group>

                      {/* <Form.Group controlId='activeCheckbox'>
                        <Button
                          variant={active ? 'success' : 'secondary'}
                          onClick={handleCheckboxChange}
                        >
                          {active ? 'Active' : 'Inactive'}
                        </Button>
                      </Form.Group> */}
                    </div>
                    <div className='modal_form_media'>
                      <Form.Group controlId='firstname'>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type='firstname'
                          name='firstname'
                          value={paymentFirstName}
                          readOnly
                        />
                      </Form.Group>
                      <br />
                      <Form.Group controlId='lastname'>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type='lastname'
                          name='lastname'
                          value={paymentLastName}
                          readOnly
                        />
                      </Form.Group>
                      <br />
                      <Form.Group controlId='email'>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type='email'
                          name='email'
                          value={paymentEmail}
                          readOnly
                        />
                      </Form.Group>
                      <br />
                      <Form.Group controlId='phone'>
                        <Form.Label>Contact</Form.Label>
                        <Form.Control
                          type='phone'
                          name='phone'
                          value={paymentPhone}
                          readOnly
                        />
                      </Form.Group>
                      <br />
                      <Form.Group controlId='amount'>
                        <Form.Label>House</Form.Label>
                        <Form.Control
                          type='house_id'
                          name='house_id'
                          value={`${house_id.house_number} ${house_id.house_type} ${house_id.house_address}`}
                          onChange={handleHouseChange}
                          readOnly
                        ></Form.Control>
                      </Form.Group>
                      <br />
                      <Form.Group controlId='amount'>
                        <Form.Label>Amount</Form.Label>
                        <Form.Control
                          type='amount'
                          name='amount'
                          value={amount}
                          onChange={handleAmountChange}
                          autoComplete='off'
                        ></Form.Control>
                      </Form.Group>
                      <br />
                      <Form.Group controlId='service_type'>
                        <Form.Label>Service_Type</Form.Label>
                        <Form.Control
                          as='select'
                          name='maintenance_id'
                          value={maintenance_id}
                          onChange={handleServiceChange}
                        >
                          <option value=''>Select service Type</option>
                          {serviceType &&
                            serviceType.map((e) => (
                              <option key={e._id} value={e._id}>
                                {e.services}
                              </option>
                            ))}
                        </Form.Control>
                      </Form.Group>
                      <br />
                      <Form.Group
                        controlId='payment_date'
                        style={{ display: 'block' }}
                        className='media_select'
                      >
                        <Form.Label style={{ display: 'block' }}>
                          Payment_Date
                        </Form.Label>
                        <DatePicker

                          type='payment_date'
                          name='payment_date'
                          selected={paymentDate}
                          onChange={(date) => setPaymentDatee(date)}
                          autoComplete='off'
                        />
                      </Form.Group>
                      <br />
                      <Form.Group controlId='period_from'>
                        <Form.Label style={{ display: 'block' }}>
                          Period_From
                        </Form.Label>
                        <DatePicker
                          type='period_from'
                          name='period_from'
                          selected={periodFromDate}
                          onChange={(date) => setPeriodFromDate(date)}
                          dateFormat='MM/dd/yyyy'
                          autoComplete='off'
                        />
                      </Form.Group>
                      <br />
                      <Form.Group controlId='to'>
                        <Form.Label style={{ display: 'block' }}>
                          To_Date
                        </Form.Label>
                        <DatePicker
                          type='to'
                          name='to'
                          selected={toDate}
                          onChange={(date) => setToDate(date)}
                          autoComplete='off'
                        />
                      </Form.Group>
                      <br />
                      <Form.Group controlId='due_date'>
                        <Form.Label style={{ display: 'block' }}>
                          Due_Date
                        </Form.Label>
                        <DatePicker
                          type='due_date'
                          name='due_date'
                          selected={dueDate}
                          onChange={(date) => setDueDatee(date)}
                          autoComplete='off'
                        />
                      </Form.Group>

                      {/* <Form.Group controlId='activeCheckbox'>
                        <Button
                          variant={active ? 'success' : 'secondary'}
                          onClick={handleCheckboxChange}
                        >
                          {active ? 'Active' : 'Inactive'}
                        </Button>
                      </Form.Group> */}
                    </div>
                    <br />

                    <Button
                      onClick={() => handlePayment()}
                      variant='primary'
                      type='button'
                    >
                      MAKE PAYMENT
                    </Button>
                  </Form>
                </Modal.Body>

                <Modal.Footer>
                  <Button
                    variant='secondary'
                    onClick={() => setShowPaymentModal(false)}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      ) : (
        <>You do not have the permission to view this page</>
      )}
    </div>
  );
}

export default AllResidents;
